import axios from 'axios'

const hoianKR = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || 'https://webapp.alpha.kr.karrotmarket.com',
})

// 글로벌은 무조건 프로덕션 찌르도록
const hoianCA = axios.create({
  baseURL: 'https://ca.karrotmarket.com',
})

const hoianGB = axios.create({
  baseURL: 'https://uk.karrotmarket.com',
})

const hoianJP = axios.create({
  baseURL: 'https://jp.karrotmarket.com',
})

// 서버에 삭제요청 하기
// export const getAwords = async (hashId: string) => {
//   const response = await hoianKR.get(`web/final_awards/2021/${hashId}`)
//   return response.data
// }

export type CountryType = 'KR' | 'CA' | 'GB' | 'JP'

export const getAwords2022 = async ({ hashId, countryCode }: { hashId: string; countryCode: CountryType }) => {
  const API_PATH = `web/final_awards/2022/${hashId}`
  let response

  try {
    if (countryCode === 'KR') {
      response = await hoianKR.get(API_PATH)
    } else if (countryCode === 'CA') {
      response = await hoianCA.get(API_PATH)
    } else if (countryCode === 'JP') {
      response = await hoianJP.get(API_PATH)
    } else if (countryCode === 'GB') {
      response = await hoianGB.get(API_PATH)
    }
  } catch (error) {
    // console.log(error)
  }

  return response?.data
}
