import styled from '@emotion/styled'
import React from 'react'

const addBr = ({ text, color }: { text: string; color?: string }) => {
  if (!text || text === '') return <></>

  const lines = text.replaceAll('\\n', '\n').split('\n')

  return lines.map((line, index) => {
    const isLast = index === lines.length - 1

    return (
      <ColoredSpan color={color} key={index}>
        {line}
        {!isLast && <br />}
      </ColoredSpan>
    )
  })
}

const ColoredSpan = styled.span<{ color: string | undefined }>`
  ${(props) => props.color && `color: ${props.color};`}
`
export default addBr
