const getCountryCode = () => {
  const hostname = window.location.hostname
  let countryCode = 'KR'

  const hostNameArray = hostname.split('.').filter((word) => word !== 'alpha')

  if (hostNameArray.length > 1) {
    const hostCountryCode = hostNameArray[1].toUpperCase()
    if (['CA', 'GB', 'JP'].includes(hostCountryCode)) {
      countryCode = hostCountryCode
    }
  }

  return countryCode
}

export default getCountryCode
