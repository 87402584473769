import React from 'react'
import { Routes, Route } from 'react-router-dom'

import YearAwards2022 from '@pages/2022'
import Redirect from '@pages/Redirect'

function App() {
  return (
    <>
      <Routes>
        <Route path="/:year" element={<Redirect />} />
        <Route path="/:year/:hashId" element={<Redirect />} />
        {/* 2022 */}
        <Route path="/2022/:hashId" element={<YearAwards2022 />} />
      </Routes>
    </>
  )
}

export default App
