import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { CountryType, getAwords2022 } from '@apis/hoian'

import getCountryCode from '../utils/getCountryCode'

interface ResponseType {
  sections: {
    my: {
      background_color: {
        dark: string
        light: string
      }
      bold_text_color: string
      cards: any
      title: string
    }
    environment: any
    main: any
    town: any
    town_cards: any
    footer: any
  }
  user: {
    hash_id: string
    nickname: string
  }
}

const useAwards2022 = () => {
  const { hashId } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ResponseType>()
  const countryCode = getCountryCode()

  const fetchData = useCallback(async () => {
    setLoading(true)
    if (hashId) {
      // const res = await getAwords2022({ hashId, countryCode: countryCode as CountryType })
      const res = await getAwords2022({ hashId, countryCode: countryCode as CountryType })
      setData(res)
      setLoading(false)
    }
  }, [countryCode, hashId])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, loading }
}

export default useAwards2022
