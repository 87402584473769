import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { bridge } from '@bridge'
import '../index.css'

const Redirect = () => {
  const navigate = useNavigate()

  // 무조건 2022년으로 리다이렉트
  useEffect(() => {
    async function fetchUserData() {
      try {
        const user = await bridge?.info?.user()
        if (user?.hashId) {
          navigate(`/2022/${user?.hashId}`, { replace: true })
        }
      } catch (error) {
        // console.log(error)
      }
    }
    fetchUserData()
  }, [navigate])

  return <></>
}

export default Redirect
