import styled from '@emotion/styled'
import React from 'react'

import { bridge } from '@bridge'
import addBr from '@utils/addBr'

const GlobalIntroSection = ({ section, countryCode }: { section: any; countryCode: string }) => {
  const isIphone = bridge.environment === 'Cupertino'

  const { background_color: backgroundColor, to, description, profile_image_url: profileImageUrl } = section

  return (
    <Wrap backgroundColor={backgroundColor.light}>
      <ContentsWrap isIphone={isIphone}>
        <Title>
          <Flex>
            <TitleFont>
              {countryCode === 'JP' ? (
                <>
                  キャロット
                  <br />
                  アワード
                  <br />
                  2022
                </>
              ) : (
                <>
                  Neighbour
                  <br />
                  of the Year
                  <br />
                  2022
                </>
              )}
              <img
                src={profileImageUrl}
                alt={'profile'}
                style={{ height: '60px', width: '60px', borderRadius: '70px', position: 'absolute', right: '0px' }}
              />
            </TitleFont>
          </Flex>
        </Title>
        <SubTexts>
          <UserText>{addBr({ text: to, color: '#ffffff' })}</UserText>
          <Description countryCode={countryCode}>{description}</Description>
        </SubTexts>
      </ContentsWrap>
    </Wrap>
  )
}

const Wrap = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  box-sizing: border-box;
  height: 90vh;
`

const ContentsWrap = styled.div<{ isIphone: boolean }>`
  max-width: 640px;
  margin: 0 auto;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;

  width: 100%;
  height: 100%;
  max-height: 1000px;

  box-sizing: border-box;
  padding: 30px 0px 46px 0px;
  ${(props) => props.isIphone && 'padding-top: 50px;'}
  ${(props) => props.isIphone && 'padding-bottom: 66px;'}
`

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  position: relative;
  text-align: left;
`

const SubTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;

  box-sizing: border-box;
  padding: 0px 24px 0px 24px;

  color: #ffffff;
`

const UserText = styled.div`
  font-family: 'Karrot Sans Text';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 135%;
`

const Description = styled.div<{ countryCode: string }>`
  text-align: left;

  font-family: 'Karrot Sans Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;

  ${(props) => (props.countryCode === 'JP' ? '' : 'word-break: keep-all;')}

  color: #ffffff;
`

const TitleFont = styled.span`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  font-size: 76px;
  line-height: 84px;

  white-space: nowrap;

  color: #ffffff;
`

export default GlobalIntroSection
