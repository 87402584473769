import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import confettiLottie from '@assets/2022/lotties/confetti.json'
import getCountryCode from '@utils/getCountryCode'

const ConfettiLottie = () => {
  const [isPlayed, setIsPlayed] = useState(false)
  const countryCode = getCountryCode()

  useEffect(() => {
    /* 한국은 아이콘이 바뀌는 시점에, 글로벌은 바로, 빵빠레 터지기 */
    if (countryCode === 'KR') {
      setTimeout(() => {
        setIsPlayed(true)
      }, 2400)
    } else {
      setIsPlayed(true)
    }
  }, [countryCode])

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: confettiLottie,
  }

  return (
    <LottieWrap>
      {isPlayed && (
        <Lottie options={lottieOptions} width={360} height={600} isStopped={!isPlayed} isClickToPauseDisabled={true} />
      )}
    </LottieWrap>
  )
}

const LottieWrap = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export default ConfettiLottie
