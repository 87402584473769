/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled'
import useAwards2022 from '@hooks/useAwards2022'
import ConfettiLottie from '@components/2022/ConfettiLottie';
import { bridge, IS_WEB } from '@bridge';
import GlobalIntroSection from '@components/2022/GlobalIntroSection';
import getCountryCode from '@utils/getCountryCode';

const IntroSection = React.lazy(() => import('@components/2022/IntroSection'))
const YearSection = React.lazy(() => import('@components/2022/YearSection/index'))
const EnviromentSection = React.lazy(() => import('@components/2022/EnviromentSection'))
const TownInfoSection = React.lazy(() => import('@components/2022/TownInfoSection'))
const TownCardsSection = React.lazy(() => import('@components/2022/TownCardsSection'))
const FooterSection = React.lazy(() => import('@components/2022/FooterSection'))

const YearAwards2022 = () => {
  const [isApp, setIsApp] = useState(true)
  const { data, loading } = useAwards2022()
  const countryCode = getCountryCode()
  
  useEffect(() => {
    if (!IS_WEB) {
      try {
        let navbarTitle = "2022 올해의 특별한 이웃✨"
        
        if (countryCode === 'JP') {
          navbarTitle = 'キャロットアワード2022'
        } else if (countryCode === 'CA' || countryCode === 'GB') {
          navbarTitle = 'Neighbour of the year 2022'
        }

        // 아이폰에서 카카오톡으로 연 웹일경우 bridge.router 에러가 나서 try/catch 해줘야한다.
        bridge.router.style({
          navbar: true,
          navbarTitle,
        })

        // bridge로 userAgent를 못가져오면 Web으로 판단 
        bridge.info
        .app()
        .then((data) => !data?.userAgent && setIsApp(false))
        .catch(() => setIsApp(false))
      } catch (e: unknown) {
        setIsApp(false)
      }
    } else {
      setIsApp(false)
    }
  }, [])

  if(loading || !data) return <></>

  const sections = data.sections;
  const { hash_id: hashId } = data.user;
  
  const { main } = sections

  return (
    <Main>
      <BoxWrap>
        <ConfettiLottie />
        {
          countryCode === 'KR' ? 
            <IntroSection section={main}/> : 
            <GlobalIntroSection section={main} countryCode={countryCode}/>
        }
        {
          sections.my && 
          <YearSection section={sections.my} />
        }
        {
          sections.environment && 
          <EnviromentSection section={sections.environment} />
        }
        {
          sections.town &&
          <TownInfoSection section={sections.town}/>
        }
        {
          sections.town_cards &&
          <TownCardsSection section={sections.town_cards}/>
        }
        {
          sections.footer &&
          <FooterSection footer={sections.footer} isApp={isApp}/>
        }
      </BoxWrap>
    </Main>
  )
}

const BoxWrap = styled.div`
  width: 100vw;
`

const Main = styled.main`
  color: #ffffff;
`



export default YearAwards2022
